<template>
  <b-card>
    <b-row v-if="loading">
      <b-col cols="6">
        <b-card>
          <b-skeleton
            animation="fade"
            width="50%"
            v-for="n in 7"
            :key="n"
          ></b-skeleton>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card>
          <b-skeleton
            animation="fade"
            width="100%"
            v-for="n in 7"
            :key="n"
          ></b-skeleton>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-skeleton-img height="250px"></b-skeleton-img>
      </b-col>
      <b-col cols="6">
        <b-skeleton-img height="250px"></b-skeleton-img>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="4">
        <div class="mb-1">
          <b-badge
            class="mr-1"
            style="width: 60px"
            :variant="statusLecturers(lecturers.lecturer_type).color"
            >{{ statusLecturers(lecturers.lecturer_type).text }}</b-badge
          >
          <b-badge
            style="width: 60px"
            :variant="typeLecturers(lecturers.status).color"
            >{{ typeLecturers(lecturers.status).text }}</b-badge
          >
        </div>
        <icon-media
          icon="MapPinIcon"
          :text="lecturers.address"
          class="mb-1"
        ></icon-media>
        <icon-media
          icon="InfoIcon"
          keyText="السعر"
          :text="lecturers.price.toLocaleString('en-US')"
          class="mb-1"
        ></icon-media>
        <icon-media
          icon="GridIcon"
          keyText="نوع الأثاث"
          :text="lecturers.cladding_type"
          class="mb-1"
        ></icon-media>
        <icon-media
          icon="MapPinIcon"
          keyText="رقم تواصل"
          :text="lecturers.phone"
          class="mb-1"
        ></icon-media>
        <icon-media
          icon="SquareIcon"
          keyText="المساحة"
          :text="lecturers.space.toLocaleString('en-US')"
          class="mb-1"
        ></icon-media>
        <icon-media
          icon="LayersIcon"
          keyText="الطابق"
          :text="lecturers.number_floors.toLocaleString('en-US')"
          class="mb-1"
        ></icon-media>
        <icon-media
          icon="CalendarIcon"
          keyText="تاريخ النشر"
          :text="lecturers.publication_date"
          class="mb-1"
        ></icon-media>
        <icon-media
          icon="CalendarIcon"
          keyText="تاريخ نهاية النشر"
          :text="lecturers.end_publication_date"
          class="mb-1"
        ></icon-media>
        
      </b-col>
      <b-col cols="8">
        <div class="d-flex flex-column">
          <div class="des-h">
            <h6 class="text-primary">التفاصيل</h6>
            <div class="px-1x" v-html="lecturers.description"></div>
          </div>

          <div class="border p-1">
            <swiper
              class="swiper-responsive-breakpoints"
              :options="swiperOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            >
              <swiper-slide
                v-for="(data, index) in lecturers.images"
                class="swiper-slide-h"
                :key="index"
              >
                <b-img :src="data.url" fluid />
              </swiper-slide>
              <div slot="pagination" class="swiper-pagination" />
            </swiper>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="6">
        <div>
          <b-card class="border">
            <h5>الفيدو</h5>
            <div class="video_mine">
              <b-embed type="video" aspect="4by3" controls poster="poster.png">
                <source :src="lecturers.video" type="video/webm" />
                <source :src="lecturers.video" type="video/mp4" />
              </b-embed>
            </div>
          </b-card>
        </div>
      </b-col>
      <b-col cols="6">
        <b-card class="border">
          <h5 >الخريطة</h5>
          <Map :lng="lecturers.longitude" :lat="lecturers.latitude" ></Map>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BSkeleton,
  BSkeletonImg,
  BEmbed,
} from "bootstrap-vue";
import Map from "@/components/map.vue";
import IconMedia from "@/components/icon-media.vue";
import { mapGetters, mapActions } from "vuex";
import manageAppService from "../../services.js";

export default {
  props: ["id"],
  components: {
    BImg,
    Swiper,
    SwiperSlide,
    BCard,
    Map,
    BRow,
    BCol,
    IconMedia,
    BBadge,
    BSkeleton,
    BSkeletonImg,
    BEmbed,
  },
  data: () => {
    return {
      /* eslint-disable global-require */
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  methods: {
    ...mapActions("manageApp/lecturers", ["getLecturersById"]),
    statusLecturers(val) {
      return manageAppService.statusLecturers(val);
    },
    typeLecturers(val) {
      return manageAppService.typeLecturers(val);
    },
  },
  computed: {
    ...mapGetters("manageApp/lecturers", ["lecturers", "loading"]),
  },
  created() {
    this.getLecturersById({ id: this.id });
  },
};
</script>
<style lang="scss">
.des-h {
  height: 200px;
}
.swiper-slide-h {
  height: 125px;
}
</style>