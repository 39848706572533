class ManageAppService {

  statusLecturers(val) {
    switch (val) {
      case "B":
        return {
          text: "بناء",
          color: "light-primary",
        };
      case "H":
        return {
          text: "بيت",
          color: "light-success",
        };
      case "S":
        return {
          text: "محل",
          color: "light-danger",
        };
      default:
        return {
          text: "",
          color: "",
        };
    }
  }

  typeLecturers(val) {
    switch (val) {
      case "S":
        return {
          text: "بيع",
          color: "light-primary",
        };
      case "I":
        return {
          text: "أستثمار",
          color: "light-success",
        };
      case "R":
        return {
          text: "إجار",
          color: "light-danger",
        };
      default:
        return {
          text: "",
          color: "",
        };
    }
  }
}

const manageAppService = new ManageAppService();
export default manageAppService;
