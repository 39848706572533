<template>
  <l-map :zoom="zoom" :center="center" v-if="map">
    <l-tile-layer :url="url" />
    <l-marker :lat-lng="markerLatLng" />
    <l-circle
      :lat-lng="circle.center"
      :radius="circle.radius"
      :color="circle.color"
    />
    <!-- <l-polygon
        :lat-lngs="polygon.latlngs"
        :color="polygon.color"
      /> -->
  </l-map>
</template>

<script>
/* eslint-disable global-require */
import { LMap, LTileLayer, LMarker, LCircle, LPolygon } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
// import { codeMarkerPolygon } from './code'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
/* eslint-disable global-require */

export default {
  props: {
    lng: {
      type:String,
      default:()=>"37.1809001"
    } ,
    lat: {
      type:String,
      default:()=>"36.2015485"
    } ,
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LPolygon,
  },
  data() {
    return {
      map: false,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 13,
      center: [36.2015485, 37.1809001],
      markerLatLng: [36.2015485, 37.1809001],
      circle: {
        center: [36.2015485, 37.1809001],
        radius: 1000,
        color: "#EA5455",
      },
      // polygon: {
      //   latlngs: [[36.2015485, 38.1809001]],
      //   color: '#7367F0',
      // },
      // codeMarkerPolygon,
    };
  },
  methods: {
    setLngLat(lng, lat) {
      this.center = [lat, lng];
      this.circle.center = [lat, lng];
      this.markerLatLng = [lat, lng];
    },
  },

  // mounted() {
  //   this.$nextTick(() => {
  //     setTimeout(() => {
  //       this.map = true;
  //     }, 100);
  //     // this.map = true; // work as expected
  //   });
  // },
  watch: {
    lng(val) {
      this.setLngLat(val, this.lat);
    },
    lat(val) {
      this.setLngLat(this.lng, val);
    },
  },
  created(){
    this.setLngLat(this.lng, this.lat);
    this.$nextTick(() => {
      setTimeout(() => {
        this.map = true;
      }, 100);
      // this.map = true; // work as expected
    });
  }
};
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
